<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success pull-right"
              @click="onDownload"
            >
              <md-icon>file_download</md-icon>
              Download XLS
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getReport"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
  },
  data: () => ({
    loading: false,
    vTable: {
      headers: [
        {
          title: 'student_number',
          mask: 'Student Number',
          sortable: false,
        },
        {
          title: 'full_name',
          mask: 'Full Name',
          sortable: false,
        },
        {
          title: 'nationality',
          sortable: false,
        },
        {
          title: 'programme_id',
          mask: 'Programme ID',
          no_format: true,
          sortable: false,
        },
        {
          title: 'start_date',
          mask: 'Start Date',
          sortable: false,
        },
        {
          title: 'status',
          sortable: false,
        },
        {
          title: 'medical_insurance',
          mask: 'Medical Insurance',
          sortable: false,
        },
        {
          title: 'accommodation',
          sortable: false,
        },
        {
          title: 'enrolment_letter',
          mask: 'Enrolment letter',
          sortable: false,
        },
      ],
      actions: [],
      values: {},
    },
    params: {},
  }),
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getReport(params) {
      this.loading = true;
      this.params = params;
      this.request('student_letters_report', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onDownload() {
      this.loading = true;
      this.request('student_letters_report', 'download', { download: true, ...this.params }, ({ data }) => {
        const filename = 'Student_letters_report.xlsx';
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>

</style>
